export type HexColor = `#${string}`;

export function addAlpha(color: HexColor, alpha: number) {
  if (color.length === 9) {
    // Strip off any existing alpha value
    color = color.slice(0, 7) as HexColor;
  }
  return (color +
    Math.round(alpha * 255)
      .toString(16)
      .toUpperCase()
      .padStart(2, '0')) as HexColor;
}

export function parseAlpha(color: HexColor) {
  if (color.length === 9) {
    const alpha = color.slice(7, 9);
    color = color.slice(0, 7) as HexColor;
    return [color, parseInt(alpha, 16) / 255] as const;
  }
  return [color, 1] as const;
}

const primary = {
  50: '#E6F0F2',
  100: '#CFE1E5',
  200: '#ADD0D9',
  300: '#8FC0CC',
  400: '#73B0BF',
  500: '#60ACBF',
  600: '#4AA3BA',
  700: '#3597B0',
  800: '#1C758C',
  900: '#0D6880',
} as const;

const primaryAlpha = {
  2: addAlpha(primary['700'], 0.02),
  4: addAlpha(primary['700'], 0.04),
  6: addAlpha(primary['700'], 0.06),
  8: addAlpha(primary['700'], 0.08),
  10: addAlpha(primary['700'], 0.1),
  20: addAlpha(primary['700'], 0.2),
  30: addAlpha(primary['700'], 0.3),
  60: addAlpha(primary['700'], 0.6),
  70: addAlpha(primary['700'], 0.7),
  80: addAlpha(primary['700'], 0.8),
};

const primaryBeta = {
  2: '#1D1F20',
  4: '#1E2223',
  6: '#1E2426',
  8: '#1F2729',
  10: '#1F292C',
  20: '#22353A',
  30: '#244249',
  60: '#2B6675',
  70: '#2E7284',
  80: '#307F93',
};

const secondary = {
  50: '#F0FDFD',
  100: '#E4FDFD',
  200: '#CAFDFD',
  300: '#B1FDFD',
  400: '#95FDFD',
  500: '#75EBEB',
  600: '#5AE0E0',
  700: '#3FD4D4',
  800: '#25BABA',
  900: '#10A1A1',
};

const tertiary = {
  50: '#F2EAE6',
  100: '#EDDDD5',
  200: '#E5C7B8',
  300: '#E0BBA8',
  400: '#D9AD98',
  500: '#D1A088',
  600: '#C79175',
  700: '#BA8266',
  800: '#AD7457',
  900: '#8C5438',
};

const black = '#262626';
const white = '#FFFFFF';
const logo = secondary['400'];

const neutral = {
  50: '#F7F8FA',
  100: '#F2F3F5',
  200: '#E7E9EE',
  300: '#D7DAE2',
  400: '#C1C4CB',
  500: '#ABADB4',
  600: '#9DA0A6',
  700: '#84868B',
  800: '#606165',
  900: '#3D3D40',
  white,
  black,
};

const blackAlpha = {
  2: addAlpha(black, 0.02),
  4: addAlpha(black, 0.04),
  6: addAlpha(black, 0.06),
  8: addAlpha(black, 0.08),
  10: addAlpha(black, 0.1),
  20: addAlpha(black, 0.2),
  30: addAlpha(black, 0.3),
  60: addAlpha(black, 0.6),
  70: addAlpha(black, 0.7),
  80: addAlpha(black, 0.8),
};

const icon = {
  primary: logo,
  tertiary: tertiary['700'],
  neutral: neutral['200'],
};

export const elevation = {
  '0dp': '#1d1d1d',
  '1dp': '#282828',
  '2dp': '#2d2d2d',
  '3dp': '#2f2f2f',
  '4dp': '#313131',
  '6dp': '#363636',
  '8dp': '#383838',
  '12dp': '#3d3d3d',
  '16dp': '#3f3f3f',
  '24dp': '#414141',
};

const bg = {
  primary: elevation['0dp'],
  secondary: elevation['4dp'],
  video: '#3597B080',
  scrollbar: '#EEEEEE59',
};

const success = {
  50: '#E1FAF1',
  100: '',
  200: '',
  300: '#77D9B7',
  400: '',
  500: '#2CB082',
  600: '',
  700: '#2CB082',
  800: '',
  900: '#04593C',
};

const warning = {
  50: '#FAF6E1',
  100: '',
  200: '',
  300: '#F2E085',
  400: '',
  500: '#D9A54C',
  600: '',
  700: '#CC822D',
  800: '',
  900: '#B2631E',
};

const error = {
  50: '#FAE6E1',
  100: '',
  200: '',
  300: '#E5A495',
  400: '',
  500: '#CC7966',
  600: '',
  700: '#B05A46',
  800: '',
  900: '#8C3723',
};

export const colors = {
  logo,
  black,
  blackAlpha,
  white,
  primary,
  primaryAlpha,
  primaryBeta,
  secondary,
  tertiary,
  neutral,
  elevation,
  success,
  warning,
  error,
  bg,
  icon,
};
