import create from 'zustand';
import shallow from 'zustand/shallow';

type DebugState = {
  isEnabled: boolean;
  actions: {
    toggle: () => void;
  };
};

const useStore = create<DebugState>((set) => ({
  isEnabled: false,
  actions: {
    toggle: () => set((state) => ({ isEnabled: !state.isEnabled })),
  },
}));

export function useDebugState() {
  return useStore(
    (state) => ({
      isEnabled: state.isEnabled,
    }),
    shallow,
  );
}

export function useDebugActions() {
  return useStore((state) => state.actions);
}
