import {
  Alert,
  AlertDescription,
  AlertIcon,
  AlertProps,
  AlertTitle,
  Flex,
} from '@chakra-ui/react';

type AlertBoxContent = string | React.ReactNode;

export type AlertBoxProps = {
  showIcon?: boolean;
} & (
  | {
      title: AlertBoxContent;
      description?: AlertBoxContent;
    }
  | {
      title?: AlertBoxContent;
      description: AlertBoxContent;
    }
) &
  AlertProps;

export function AlertBox({
  showIcon,
  title,
  description,
  ...props
}: AlertBoxProps) {
  return (
    <Alert
      variant="left-accent"
      flexDirection="column"
      alignItems="flex-start"
      overflow="unset"
      gap="2"
      {...props}
    >
      {title ? (
        <Flex>
          {showIcon && <AlertIcon />}
          <AlertTitle>{title}</AlertTitle>
        </Flex>
      ) : null}
      {description ? (
        <AlertDescription w="full">{description}</AlertDescription>
      ) : null}
    </Alert>
  );
}
