import { useEffect } from 'react';
import { useShallowCompareEffect } from 'react-use';
import { useRouter } from 'next/router';
import * as Sentry from '@sentry/nextjs';

import {
  AnalyticsClient,
  AnalyticsContext,
  GeneralTrackingEvent,
} from '@arena-labs/analytics';
import { UserProfile } from '@arena-labs/shared-models';
import { queryClient } from '@strive/api';

import {
  identifyLogRocket,
  reportErrorLogRocket,
  resetLogRocket,
} from '../lib/logrocket';
import { reportErrorSentry } from '../lib/sentry';

// Feed errors from React Query into Sentry and LogRocket
queryClient.getQueryCache().config.onError = (error, query) => {
  const wrapped = new Error(`Query ${query.queryHash}: ${error}`, {
    cause: error,
  });
  reportErrorSentry(wrapped);
  reportErrorLogRocket(wrapped);
};

export type UserTelemetryProps = {
  analytics: AnalyticsClient;
  trackPageLoad?: boolean;
  user?: UserProfile;
};

export function UserTelemetry({
  analytics,
  user,
  trackPageLoad = true,
  children,
}: React.PropsWithChildren<UserTelemetryProps>) {
  // Set up global analytics context with user journey data
  // Send a 'PageLoad' event when the URL changes
  const router = useRouter();
  const url = router.asPath;
  useEffect(() => {
    if (trackPageLoad) {
      analytics.logEvent(GeneralTrackingEvent.PageLoad, { url });
    }
  }, [trackPageLoad, url, analytics]);

  // Identify the user with Sentry, LogRocket, and Amplitude
  const attributes = {
    version: process.env.VERSION || 'unknown',
    sha: process.env.GIT_SHA || 'unknown',
    ...(user && {
      team: user.profile.team_id,
      teamName: user.profile.team,
      email: user.email,
      name: user.first_name,
      roles: user.profile.roles.toString(),
    }),
  };

  useShallowCompareEffect(() => {
    if (user?.id) {
      analytics.setUserId(String(user?.id));
      analytics.setUserProperties(attributes);
      Sentry.setUser({
        id: String(user.id),
        ...attributes,
      });
      identifyLogRocket(String(user.id), attributes);
    } else {
      analytics.clearUser();
      Sentry.configureScope((scope) => scope.setUser(null));
      resetLogRocket();
    }
  }, [user?.id, attributes]);

  return (
    <AnalyticsContext context={analytics.context}>{children}</AnalyticsContext>
  );
}
