import { isNull, omitBy } from 'lodash-es';
import LogRocket from 'logrocket';
import setupLogRocketReact from 'logrocket-react';

if (typeof window !== 'undefined') {
  if (process.env.NEXT_PUBLIC_LOG_ROCKET_ID) {
    LogRocket.init(process.env.NEXT_PUBLIC_LOG_ROCKET_ID, {
      network: {
        requestSanitizer: (request) => {
          if (request.url.includes('api/auth/callback')) {
            // Strip out username/password
            request.body = undefined;
          }
          return request;
        },
      },
    });
    setupLogRocketReact(LogRocket);
  }
}

export function identifyLogRocket(
  id: string,
  attributes: Record<string, string | number | boolean | null>,
) {
  const attrs = omitBy(attributes, isNull) as Record<
    string,
    string | number | boolean
  >;
  if (process.env.NEXT_PUBLIC_LOG_ROCKET_ID) {
    LogRocket.identify(id, attrs);
  }
}

export function resetLogRocket() {
  if (process.env.NEXT_PUBLIC_LOG_ROCKET_ID) {
    LogRocket.startNewSession();
  }
}

export function reportErrorLogRocket(error: unknown) {
  if (process.env.NEXT_PUBLIC_LOG_ROCKET_ID) {
    if (error instanceof Error) {
      LogRocket.captureException(error);
    } else {
      LogRocket.captureMessage(String(error));
    }
  }
}
