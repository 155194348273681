import { Box, BoxProps } from '@chakra-ui/react';

export function ScreenContainer({ children, ...props }: BoxProps) {
  const vars = {
    '--safe-screen-height':
      'calc(100 * var(--vh, 1vh) - env(safe-area-inset-top))',
  } as React.CSSProperties;

  return (
    <Box
      paddingTop="env(safe-area-inset-top)"
      h="calc(100 * var(--vh, 1vh))"
      maxHeight="calc(100 * var(--vh, 1vh))"
      style={vars}
      {...props}
    >
      {children}
      <Box
        top="0"
        w="full"
        height="env(safe-area-inset-top)"
        position="fixed"
        bg="strive.statusbar"
      />
    </Box>
  );
}
