import 'stream-chat-react/dist/css/v2/index.css';
import '../styles.css';

import React from 'react';
import { NextPage } from 'next';
import { AppProps } from 'next/app';
import Head from 'next/head';
import { QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { enableMapSet } from 'immer';
import { SessionContextValue, SessionProvider } from 'next-auth/react';

import { TrackingTag, useRouterAnalytics } from '@arena-labs/analytics';
import { ThemeProvider } from '@arena-labs/strive2-ui';
import { queryClient } from '@strive/api';

import { useDebugState } from '../components/layout/use-debug-state';
import { UserTelemetry } from '../components/user-telemetry';
import { SyncAuthTokenFromSession } from '../lib/api';

enableMapSet();

type CoachUINextPage = NextPage & {
  // Shared layout - https://nextjs.org/docs/basic-features/layouts
  getLayout?: (
    page: React.ReactElement,
    pageProps?: unknown,
  ) => React.ReactNode;
  analyticsTags?: TrackingTag[];
  disableAnalyticsPageLoad?: boolean;
};

type AppPropsWithLayout = Omit<AppProps, 'pageProps'> & {
  Component: CoachUINextPage;
  pageProps: {
    session: SessionContextValue['data'];
  };
};

function CustomApp({
  Component,
  pageProps: { session, ...pageProps },
}: AppPropsWithLayout) {
  const getLayout = Component.getLayout ?? ((page: React.ReactElement) => page);
  const debugState = useDebugState();
  const analytics = useRouterAnalytics({
    tags: Component.analyticsTags,
  });

  return (
    <>
      <Head>
        <title>Welcome to strive dashboard!</title>
      </Head>
      <ThemeProvider>
        <QueryClientProvider client={queryClient}>
          <SessionProvider session={session}>
            <UserTelemetry
              analytics={analytics}
              user={session?.user}
              trackPageLoad={!Component.disableAnalyticsPageLoad}
            >
              <SyncAuthTokenFromSession />
              {getLayout(<Component {...pageProps} />, pageProps)}
            </UserTelemetry>
          </SessionProvider>
          {debugState.isEnabled && <ReactQueryDevtools initialIsOpen />}
        </QueryClientProvider>
      </ThemeProvider>
    </>
  );
}

export default CustomApp;
