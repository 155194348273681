import * as Sentry from '@sentry/nextjs';

export function identifySentry(
  id: string,
  attributes: Record<string, string | number | boolean | null>,
) {
  Sentry.setUser({
    id,
    ...attributes,
  });
}

export function resetSentry() {
  Sentry.configureScope((scope) => scope.setUser(null));
}

export function reportErrorSentry(error: unknown) {
  Sentry.captureException(error);
}
