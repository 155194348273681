import { useEffect } from 'react';
import { ZodiosHooks } from '@zodios/react';
import { useSession } from 'next-auth/react';

import { AuthResponse } from '@arena-labs/shared-models';
import {
  coachPortalApi,
  createApiClient,
  setAuthTokensOverride,
} from '@strive/api';

let tokens: AuthResponse | null = null;

export function setTokens(newTokens: typeof tokens) {
  tokens = newTokens;
}

export const coachApi = createApiClient(coachPortalApi, {
  getAuthTokens: () => tokens,
  setAuthTokens: (newTokens) => {
    tokens = newTokens;
  },
  onSessionExpired: () => {
    tokens = null;
  },
});

export const $API = new ZodiosHooks('api', coachApi);

/**
 * React component that injects the auth token from the
 * session into the API client
 */
export function SyncAuthTokenFromSession() {
  const session = useSession();
  useEffect(() => {
    if (session.data) {
      setTokens(session.data.tokens);
      setAuthTokensOverride(session.data.tokens);
    } else {
      setTokens(null);
    }
  }, [session.data]);

  return null;
}
