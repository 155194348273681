import NextLink from 'next/link';
import { Icon, Link, VisuallyHidden } from '@chakra-ui/react';

import { ChevronLeftIcon } from '../icons';
import { useBackButtonWithFallback } from '../utils/router-history';

export function BackLink({ href, color }: { href: string; color: string }) {
  const goBack = useBackButtonWithFallback(href);

  return (
    <Link
      href={href}
      as={NextLink}
      p={0}
      onClick={(e) => {
        e.preventDefault();
        goBack();
      }}
    >
      <VisuallyHidden>Back to previous page</VisuallyHidden>
      <Icon as={ChevronLeftIcon} boxSize={4} color={color} />
    </Link>
  );
}
