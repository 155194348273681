import {
  AlertProps,
  DrawerProps,
  extendTheme,
  ModalProps,
  theme as base,
} from '@chakra-ui/react';

import {
  borderRadius,
  breakpoints,
  colors,
  fonts,
  fontSizes,
  gradientsCSS,
  layerStyles,
  letterSpacings,
  lineHeights,
  shadows,
  textStyles,
} from '@strive/theme/tokens';

import { CardStyles } from '../common/card';
import { CheckboxButtonTheme } from '../common/checkbox-button';

const config = {
  initialColorMode: 'light',
  useSystemColorMode: false,
};

const gray = {
  50: '#F2F2F2',
  100: '#DCE1E6',
  200: '#C4C4C4',
  250: '#B4B1B1',
  300: '#969696',
  400: '#808080',
  500: '#666666',
  600: '#404040',
  700: '#323232',
  800: '#2D2D2D',
  900: '#191E22',
  medium: '#8c8f91',
  dark: '#53565a',
  callout: '#55585b',
};

const brandColors = {
  900: '#033652',
  800: '#03637d',
  700: '#0091a8',
  600: '#00bfd4',
  500: '#00eeff',
  400: '#3597B0',
  300: '#6ef5fa',
  200: '#a3faf5',
  100: '#dbfcf2',
  base: '#00eeff',
  bgDark: '#013238',
  contrast: 'black',
  slider: '#2bd5e4',
};

const strive = {
  primary: brandColors['500'],
  secondary: brandColors['800'],
  tertiary: '#2CFCFC',
  background: colors.bg.primary,
  statusbar: colors.bg.primary,
  card: gray['700'],
  cardText: colors.neutral['200'],
  cardTextSecondary: gray['200'],
  cardTextTertiary: gray['300'],
  card2: {
    bg: gray['500'],
    bgHover: gray['700'],
    fg: gray['50'],
  },
  card3: {
    bg: gray['700'],
    bgHover: gray['600'],
    fg: gray['50'],
  },
  card4: {
    bg: gray['800'],
    bgHover: gray['600'],
    fg: gray['50'],
  },
  card5: {
    bg: brandColors.bgDark,
    fg: gray['50'],
  },
  brand: brandColors['500'],
  pallete: {
    alpha: '#1D3356',
    beta: '#314A82',
    gamma: '#413689',
    delta: '#3E4C92',
    epsilon: '#833840',
    zeta: '#821039',
    explorations: '#388795',
    pillar1: '#1A4450',
    pillar2: '#007E8F',
    pillar3: '#00857C',
    pillar4: '#018354',
    pillar5: '#26616E',
    pillar6: '#153036',
  },
};

const makeColorScheme = (
  name: string,
  mainColor: string,
  accentColor: string,
  textColor = accentColor,
) => ({
  [name]: {
    main: mainColor,
    accent: accentColor,
    text: textColor,
  },
  [`${name}-inverted`]: {
    main: textColor,
    accent: accentColor,
    text: mainColor,
  },
});
const colorScheme = {
  ...makeColorScheme('primary', '#05627C', 'white'),
  ...makeColorScheme('secondary', 'white', strive.background),
  ...makeColorScheme('default', 'white', strive.background),
  ...makeColorScheme('dark', gray[800], 'white'),
  ...makeColorScheme('danger', base.colors.red[800], 'white'),
  ...makeColorScheme('alpha', base.colors.blue['800'], 'white'),
  ...makeColorScheme('beta', base.colors.green['800'], 'white'),
  ...makeColorScheme('gamma', base.colors.red['800'], 'white'),
  ...makeColorScheme('pillar-1', strive.pallete.pillar1, 'white'),
  ...makeColorScheme('pillar-2', strive.pallete.pillar2, 'white'),
  ...makeColorScheme('pillar-3', strive.pallete.pillar3, 'white'),
  ...makeColorScheme('pillar-4', strive.pallete.pillar4, 'white'),
  ...makeColorScheme('pillar-5', strive.pallete.pillar5, 'white'),
  ...makeColorScheme('pillar-6', strive.pallete.pillar6, 'white'),
  ...makeColorScheme('explorations', strive.pallete.explorations, 'white'),
};

const legacyColors = {
  strive: {
    ...strive,
    colorScheme,
    pillar: [
      'hotpink', // just an obvious color so you notice that this is 0-indexed
      strive.pallete.pillar1,
      strive.pallete.pillar2,
      strive.pallete.pillar3,
      strive.pallete.pillar4,
      strive.pallete.pillar5,
      strive.pallete.pillar6,
    ],
    auth: {
      bg: strive.background,
    },
    appBanner: {
      fg: strive.card3.fg,
      bg: colors.bg.primary,
      icon: strive.primary,
    },
    appFooter: {
      bg: strive.card4.bg,
      fg: colors.white,
      activeFg: strive.primary,
      activeBg: strive.card4.bg,
    },
    appMenu: {
      bg: strive.card4.bg,
      fg: strive.card4.fg,
      selectedBg: strive.card3.bg,
      selectedFg: strive.card3.fg,
      borderColor: strive.cardTextTertiary,
    },
    backBanner: {
      bg: colors.bg.secondary,
      fg: strive.cardText,
    },
    data: {
      positive: '#5CCEDE',
      even: '#00C2FF',
      negative: '#769CFF',
    },
    tabs: {
      bg: strive.card3.bg,
      fg: strive.cardTextSecondary,
      selectedBg: strive.secondary,
      selectedFg: strive.primary,
    },
    alert: {
      bg: strive.card,
      fg: strive.cardText,
    },
    button: {
      primary: {
        bg: colors.primary['700'],
        fg: 'white',
      },
      secondary: {
        bg: gray['600'],
        fg: 'white',
      },
      action: {
        bg: '#05627C',
        fg: 'white',
      },
      link: {
        fg: strive.primary,
      },
      icon: {
        fg: 'white',
      },
    },
    modal: {
      bg: strive.background,
      fg: strive.cardText,
      colorScheme: {
        secondary: strive.card4.bg,
      },
    },
    input: {
      bg: 'transparent',
      bgFocused: '#ffffff11',
      fg: strive.cardText,
      fgFocused: strive.cardText,
      border: gray['400'],
      borderFocused: gray['300'],
      errorBg: '#591111',
      errorFg: 'white',
    },
    link: strive.primary,
    audio: {
      bg: strive.background,
      fg: strive.primary,
    },
    aci: {
      card: strive.card4.bg,
      color: strive.card4.fg,
      callout: gray['600'],
      slider: 'primary.700',
      sliderBar: 'primary.700',
      check: 'white',
    },

    glass: '#3597B080',
  },

  brand: brandColors,

  gray,

  red: {
    50: '#FFF5F5',
    100: '#FED7D7',
    200: '#FEB2B2',
    300: '#F86868',
    400: '#F45151',
    500: '#E53E3E',
    600: '#C53030',
    700: '#9B2C2C',
    800: '#822727',
    900: '#63171B',
  },

  alert: {
    error: '#FF3E48',
    success: '#03FF00',
    info: '#00bfd4',
    warning: '#FFC107',
  },

  status: {
    error: {
      bg: '#591111',
      fg: 'white',
    },
    success: {
      bg: '#2a792a',
      fg: 'white',
    },
    info: {
      bg: '#1E5563',
      fg: 'white',
    },
    warning: {
      bg: '#BA8266',
      fg: 'white',
    },
  },

  aci: {
    current_stress: '#00EEFF',
    current_energy: '#FF5F1F',
    current_focus: '#C6FE46',
    challenge_anticipated: '#FFEB00',
    preparedness: '#ACA8FF',
    make_key_decisions: '#01EA6D',
    clinical_performance: '#FF9372',
    recharging_needed: '#FFA01F',
    recharging_ability: '#FF45CC',
  },

  snapshot: {
    average_heart_rate: '#FF45CC',
    average_heart_rate_accent: '#472644',
    hrv: '#FF5F1F',
    hrv_accent: '#472B21',
    sleep_efficiency: '#00EEFF',
    sleep_efficiency_accent: '#2A3D4E',
    rem_percentage: '#C6FE46',
    rem_percentage_accent: '#3C4B29',
    slow_wave_sleep: '#8B86FF',
    slow_wave_sleep_accent: '#32354E',
  },

  scheduling: {
    icon: '#B1C4E0',
    background: '#373F48',
  },

  protocol: {
    'sleep-optimization': {
      fg: '#ACA8FF',
      bg: '#1E2248',
    },
    'waking-up': {
      fg: '#FFEB00',
      bg: '#47471B',
    },
    deactivation: {
      fg: '#71B8FF',
      bg: '#2A3D4E',
    },
    'goal-setting': {
      fg: '#ED78D3',
      bg: '#321331',
    },
    'self-awareness': {
      fg: '#EC6A37',
      bg: '#34190E',
    },
    'ramping-up': {
      fg: '#FEB2B2',
      bg: '#63171B',
    },
    'winding-down': {
      fg: '#F2A442',
      bg: '#453924',
    },
    'active-recovery': {
      fg: '#D2FD68',
      bg: '#3F4B2D',
    },
  },

  whoopProtocol: {},
};

legacyColors.whoopProtocol = {
  morning: legacyColors.protocol['waking-up'],
  day: legacyColors.protocol.deactivation,
  evening: legacyColors.protocol['sleep-optimization'],
};

const globalStyles = {
  '@supports (height: 1dvh)': {
    html: {
      '--dvh': '1dvh',
    },
  },
  html: {
    height: '100%',
  },
  'html:focus-within': {
    scrollBehavior: 'smooth',
  },
  body: {
    height: '100%',
    minHeight: 'calc(100 * var(--vh, 1vh))',
    backgroundColor: 'bg.primary',
    color: 'neutral.200',
  },
  '#__next': {
    height: '100%',
    minHeight: 'calc(100 * var(--vh, 1vh))',
  },
  ':focus:not(:focus-visible), :focus:not(:focus-visible)+[data-focus]': {
    outline: 'none',
    boxShadow: 'none',
  },
  '.ReactQueryDevtoolsPanel input': {
    fontSize: '1rem !important',
  },
};

const inputSelectStyles = {
  defaultProps: {
    variant: 'default',
  },
  variants: {
    default: {
      field: {
        color: 'strive.input.fg',
        bg: 'strive.input.bg',
        border: '1px solid',
        borderColor: 'strive.input.border',
        _focus: {
          fg: 'strive.input.fgFocused',
          bg: 'strive.input.bgFocused',
          borderColor: 'strive.input.borderFocused',
        },
        '> option, > optgroup': {
          bg: 'strive.input.bg',
        },
      },
    },
    '1dp': {
      field: {
        bg: layerStyles['1dp'].bg,
        fontSize: 'p1',
        fontWeight: '600',
        border: '1px solid',
        borderColor: 'neutral.900',
        py: '3',
      },
    },

    dark: {
      field: {
        color: 'white',
        bg: 'gray.900',
        _hover: {
          bg: 'gray.900',
        },
        _focus: {
          color: 'white',
          bg: 'gray.900',
          border: '1px solid',
          borderColor: 'gray.400',
        },
        '> option, > optgroup': {
          bg: 'gray.900',
        },
      },
    },
  },
};

const getAlertColor = ({ status, colorScheme }: AlertProps) =>
  (status && (legacyColors.alert as Record<string, string>)[status]) ??
  `${colorScheme}.900`;

const noUnderline = {
  textDecoration: 'none',
  _hover: {
    textDecoration: 'none',
  },
  _active: {
    textDecoration: 'none',
  },
  _focus: {
    textDecoration: 'none',
  },
};

export const theme = extendTheme({
  config,
  breakpoints,
  textStyles: textStyles,
  layerStyles: layerStyles,
  fonts: {
    ...fonts,
    brand: `TitlingGothicFB Wide Standard, ${fonts.heading}`,
  },
  fontSizes: {
    ...fontSizes,
    '5xs': '0.25rem',
    '4xs': '0.375rem',
    '3xs': '0.5rem',
    '2xs': '0.625rem',
  },
  letterSpacings: letterSpacings,
  lineHeights: lineHeights,
  colors: {
    ...legacyColors,
    ...colors,
  },
  gradients: gradientsCSS,
  styles: { global: globalStyles },
  shadows: {
    ...base.shadows,
    md: '0px 1px 14px 0px #0D1E4112',
    ...shadows,
  },
  radii: borderRadius,
  components: {
    Card: CardStyles,

    Button: {
      defaultProps: {
        colorScheme: 'default',
        fontSize: 'sm',
      },
      baseStyle: {
        _focus: {
          '&:not(:focus-visible)': {
            boxShadow: 'none',
          },
        },
        borderRadius: 'button',
        fontWeight: 'normal',
      },
      variants: {
        solid: ({ colorScheme = 'default' }) => ({
          bg: `strive.colorScheme.${colorScheme}.main`,
          color: `strive.colorScheme.${colorScheme}.text`,
          fontSize: 'sm',
        }),
        outline: ({ colorScheme = 'default' }) => ({
          bg: `transparent`,
          color: `strive.colorScheme.${colorScheme}.main`,
          borderColor: `strive.colorScheme.${colorScheme}.main`,
          border: `1px solid`,
          fontSize: 'sm',
        }),
        primary: () => ({
          bg: `strive.button.primary.bg`,
          color: `strive.button.primary.fg`,
          boxShadow: '0px 4px 4px 0px #00000040',
          fontWeight: 'bold',
          borderRadius: 'button',
          py: 4,
          height: 'auto',
        }),
        secondary: () => ({
          bg: `elevation.8dp`,
          color: `strive.button.secondary.fg`,
          boxShadow: '0px 4px 4px 0px #00000040',
          fontWeight: 'bold',
          borderRadius: 'button',
          py: 4,
          height: 'auto',
        }),
        tertiary: () => ({
          padding: '12px 40px',
          bg: `neutral.200`,
          color: `primary.900`,
          boxShadow: '0px 4px 4px 0px #00000040',
          fontWeight: '510',
          borderRadius: 'md',
          height: 'auto',
        }),
        toggle: ({ colorScheme = 'default' }) => ({
          bg: `strive.colorScheme.${colorScheme}.main`,
          color: `strive.colorScheme.${colorScheme}.text`,
          ['&:hover:disabled']: {
            bg: `strive.colorScheme.${colorScheme}.main`,
          },
          ['&[aria-pressed=true]']: {
            bg: `strive.colorScheme.${colorScheme}.text`,
            color: `strive.colorScheme.${colorScheme}.main`,
          },
        }),
        ['toggle-outline']: ({ colorScheme = 'default' }) => ({
          bg: `transparent`,
          color: `strive.colorScheme.${colorScheme}.main`,
          borderColor: `strive.colorScheme.${colorScheme}.main`,
          border: `1px solid`,
          ['&[aria-pressed=true]']: {
            bg: `strive.colorScheme.${colorScheme}.main`,
            color: `strive.colorScheme.${colorScheme}.text`,
            borderColor: `strive.colorScheme.${colorScheme}.main`,
          },
        }),
        'brand-primary': {
          fontFamily: 'brand',
          color: 'strive.primary',
          bg: `strive.secondary`,
          _active: {
            bg: `strive.brand`,
          },
          _hover: {
            bg: `strive.brand`,
          },
          _focus: {
            ring: 2,
            ringColor: `strive.brand`,
          },
          borderRadius: 'sm',
        },
        dark: ({ colorScheme = 'brand' }) => ({
          fontFamily: 'brand',
          bg: `${colorScheme}.800`,
          _active: {
            bg: `${colorScheme}.900`,
          },
          _hover: {
            bg: `${colorScheme}.900`,
          },
          _focus: {
            ring: 2,
            ringColor: `${colorScheme}.500`,
          },
          borderRadius: 'sm',
        }),
        'card-action': {
          borderRadius: 'button',
          color: 'strive.button.action.fg',
          bg: 'strive.button.action.bg',
          _hover: {
            bg: 'strive.card4.bgHover',
          },
        },
        inline: ({ colorScheme = 'brand' }) => ({
          color: `strive.button.${colorScheme}.fg`,
          bg: 'transparent',
          _focus: {
            ring: 2,
            ringColor: `strive.button.${colorScheme}.ringColor`,
          },
          p: 0,
          height: 'auto',
          lineHeight: 'normal',
          verticalAlign: 'baseline',
        }),
        link: {
          color: 'strive.button.link.fg',
        },
        'underline-link': {
          display: 'inline-block',
          textDecoration: 'underline',
          height: 'auto',
          color: 'neutral.400',
        },
      },
    },

    Checkbox: {
      baseStyle: {
        control: {
          _checked: {
            color: 'gray.900',
          },
        },
      },
    },

    CheckboxButton: CheckboxButtonTheme,

    Radio: {
      defaultProps: {
        color: 'strive.secondary',
      },
      baseStyle: {
        control: {
          _checked: {
            color: 'transparent',
          },
        },
      },
    },

    Link: {
      sizes: {
        lg: {
          h: 12,
          minW: 12,
          fontSize: 'lg',
          px: 6,
        },
        md: {
          h: 10,
          minW: 10,
          fontSize: 'sm',
          px: 4,
        },
        sm: {
          h: 8,
          minW: 8,
          fontSize: 'sm',
          px: 3,
        },
        xs: {
          h: 6,
          minW: 6,
          fontSize: 'xs',
          px: 2,
        },
      },
      variants: {
        unstyled: {},
        underline: {
          textDecoration: 'underline',
          textUnderlineOffset: '0.1em',
          fontWeight: 'normal',
          color: 'strive.link',
          px: 0,
          minW: 'auto',
        },
        outline: ({ colorScheme = 'default' }) => ({
          bg: `transparent`,
          color: `strive.colorScheme.${colorScheme}.main`,
          borderColor: `strive.colorScheme.${colorScheme}.main`,
          border: `1px solid`,
          fontWeight: 'normal',
          px: 4,
          lineHeight: 1.2,
          verticalAlign: 'middle',
          justifyContent: 'center',
          alignItems: 'center',
          display: 'inline-flex',
          borderRadius: 'button',
          ...noUnderline,
        }),
        solid: ({ colorScheme = 'default' }) => ({
          bg: `strive.colorScheme.${colorScheme}.main`,
          color: `strive.colorScheme.${colorScheme}.text`,
          fontWeight: 'normal',
          fontSize: 'sm',
          px: 4,
          height: 10,
          lineHeight: 1.2,
          verticalAlign: 'middle',
          justifyContent: 'center',
          alignItems: 'center',
          display: 'inline-flex',
          borderRadius: 'button',
          ...noUnderline,
        }),
        button: {
          bg: `strive.button.primary.bg`,
          color: `strive.button.primary.fg`,
          boxShadow: '0px 4px 4px 0px #00000040',
          fontWeight: 'bold',
          borderRadius: 'button',
          py: 4,
          height: 'auto',
          textAlign: 'center',
        },
        'card-action': {
          display: 'flex',
          placeItems: 'center',
          borderRadius: 'sm',
          bg: 'gray.800',
          border: '1px solid',
          px: 3,
          py: 2,
          lineHeight: '1.2',
          fontWeight: 'bold',
          overflow: 'hidden',
          _hover: {
            bg: 'gray.700',
            textDecoration: 'none',
          },
        },
      },
    },

    Input: { ...inputSelectStyles },

    Select: { ...inputSelectStyles },

    FormError: {
      baseStyle: {
        text: {
          display: 'inline-flex',
          color: 'strive.input.errorFg',
          bg: 'strive.input.errorBg',
          px: 2,
          py: 1,
          borderRadius: 'sm',
        },
        icon: {
          color: 'strive.input.errorFg',
          bg: 'strive.input.errorBg',
        },
      },
    },

    Alert: {
      defaultProps: {
        variant: 'left-accent',
      },
      baseStyle: (props: AlertProps) => ({
        container: {
          borderRadius: 'md',
        },
        title: {
          textTransform: 'uppercase',
        },
        icon: {
          color: 'currentColor',
        },
      }),
      variants: {
        'top-accent': (props: AlertProps) => ({
          container: {
            pt: 3,
            borderTopWidth: '8px',
            borderTopColor: getAlertColor(props),
            color: 'strive.cardText',
          },
        }),
        'left-accent': (props: AlertProps) => ({
          container: {
            borderStartWidth: '8px',
            borderStartColor: getAlertColor(props),
            bg: strive.card4.bg,
            color: strive.card4.fg,
          },
        }),
        toast: (props: AlertProps) => ({
          container: {
            bg: strive.card4.bg,
            color: strive.card4.fg,
            borderStartWidth: '8px',
            borderStartColor: getAlertColor(props),
            marginBottom: 10,
          },
        }),
      },
    },

    Menu: {
      defaultProps: {
        variant: 'default',
      },
      baseStyle: {
        overlay: {
          backdropFilter: 'brightness(30%)',
        },
      },
      variants: {
        default: {
          list: {
            py: 1,
            borderColor: 'gray.800',
            borderRadius: 'sm',
          },
          item: {
            color: 'strive.appMenu.fg',
            _active: {
              bg: 'strive.appMenu.selectedBg',
              color: 'strive.appMenu.selectedFg',
            },
            _focus: {
              bg: 'strive.appMenu.selectedBg',
              color: 'strive.appMenu.selectedFg',
            },
          },
        },
      },
    },

    Drawer: {
      defaultProps: {
        variant: 'responsive',
      },
      baseStyle: (props: DrawerProps) => ({
        dialog: {
          background: props.colorScheme
            ? `strive.modal.colorScheme.${props.colorScheme}`
            : 'strive.modal.bg',
          // FIX: Workaround header/footer being obscured by address bar/footer on android/ios
          // See: https://dev.to/admitkard/mobile-issue-with-100vh-height-100-100vh-3-solutions-3nae
          //
          // `--safe-screen-height` is set via strive-ui ScreenContainer
          // One day we will be able to use `100 dvh` instead of `100vh`
          // (https://css-tricks.com/the-large-small-and-dynamic-viewports/)
          maxHeight: 'var(--safe-screen-height)',
        },
        header: {
          marginTop: 'env(safe-area-inset-top)',
          position: 'relative',
        },
        overlay: {
          backdropFilter: 'brightness(30%)',
        },
      }),
      variants: {
        responsive: {
          dialog: {
            // Responsive - set max width
            '--drawer-max-width': '600px !important',
            maxWidth: '600px !important',
            maxHeight: 'calc(100 * var(--vh))',
            left: 'clamp(0px, calc(50vw - calc(var(--drawer-max-width) / 2)), var(--drawer-max-width)) !important',
          },
        },
        containerless: {
          dialogContainer: {
            pointerEvents: 'none',
          },
          dialog: {
            pointerEvents: 'auto',
          },
        },
      },
    },

    Modal: {
      baseStyle(props: ModalProps) {
        return {
          dialog: {
            background: props.colorScheme
              ? `strive.modal.colorScheme.${props.colorScheme}`
              : 'strive.modal.bg',
            bgGradient:
              'linear(to-br, rgb(0 0 0 / 0.2), rgb(255 255 255 / 0.05) 40%, rgb(255 255 255 / 0.05) 60%, rgb(0 0 0 / 0.2) 100%)',
            mx: 6,
            boxShadow: '24dp',
          },
          overlay: {
            backdropFilter: 'brightness(30%)',
          },
        };
      },

      variants: {
        'video-modal': {
          dialog: {
            background: 'rgba(43, 51, 63, 0.8)',
            bgGradient: 'none',
          },
        },
        default: {
          dialog: {
            background: 'elevation.24dp',
            bgGradient: 'none',
            color: 'neutral.300',
          },
        },
        primary: {
          dialog: {
            background: 'bg.primary',
            bgGradient: 'linear(to-r, primaryAlpha.20, primaryAlpha.20)',
            color: 'neutral.300',
          },
        },
        'bottom-sheet': {
          dialog: {
            bottom: 0,
            left: 0,
            right: 0,
            marginTop: 'auto',
            marginBottom: 'env(safe-area-inset-bottom)',
            marginX: 0,
            maxHeight: '50vh',
            borderTopRadius: '3xl',
            background: 'elevation.4dp',
            bgGradient: 'none',
          },
          overlay: {
            backdropFilter: 'brightness(70%)',
          },
          header: {
            ...textStyles.h2,
          },
          content: {
            ...textStyles.p1,
          },
        },
      },

      sizes: {
        full: {
          dialog: {
            height: '$100vh',
          },
          header: {
            marginTop: 'env(safe-area-inset-top)',
          },
          footer: {
            paddingBottom: 'env(safe-area-inset-bottom)',
          },
        },
      },
    },

    Tabs: {
      defaultProps: {
        variant: 'brand',
        size: 'sm',
      },
      sizes: base.components.Tabs.sizes,
      variants: {
        brand: {
          tablist: {
            borderBottom: '2px solid',
            borderColor: 'gray.600',
          },
          tab: {
            fontWeight: 'bold',
            minWidth: 'fit-content',
            borderBottom: '2px solid',
            borderColor: 'transparent',
            marginBottom: '-2px',
            py: 2,
            _active: {
              bg: 'transparent',
            },
            _selected: {
              color: 'strive.tabs.selectedFg',
              borderColor: 'currentColor',
            },
            _disabled: {
              opacity: 0.4,
              cursor: 'not-allowed',
            },
            sx: {
              ':focus:not(:focus-visible)': {
                boxShadow: 'none',
              },
            },
          },
        },

        secondary: {
          tablist: {
            border: undefined,
            borderBottom: '1px solid',
            borderColor: 'gray.400',
            bg: 'strive.tabs.bg',
          },
          tab: {
            borderRadius: 'md',
            fontWeight: 'bold',
            minWidth: 'fit-content',
            border: '2px solid',
            borderColor: 'transparent',
            borderTop: 'none',
            marginBottom: '-2px',
            lineHeight: 1,
            px: 2,
            pt: 1.5,
            pb: 1,
            color: 'strive.tabs.fg',
            mt: '-1px',
            _selected: {
              bg: 'strive.tabs.selectedBg',
              color: 'strive.tabs.selectedFg',
            },
            _disabled: {
              opacity: 0.4,
              cursor: 'not-allowed',
            },
            sx: {
              ':focus:not(:focus-visible)': {
                boxShadow: 'none',
              },
            },
          },
        },
      },
    },

    Skeleton: {
      defaultProps: {
        startColor: 'gray.500',
        speed: 1,
      },
    },

    Popover: {
      baseStyle: {
        content: {
          bg: 'gray.600',
          borderColor: 'transparent',
          '--popper-arrow-bg': gray['600'],
          '--popper-arrow-shadow-color': 'transparent',
        },
        header: {
          bg: 'gray.600',
        },
      },
      variants: {
        default: {
          content: {
            bg: 'elevation.12dp',
            bgGradient: 'none',
            color: 'neutral.300',
            boxShadow: '12dp',
            '--popper-arrow-bg': colors.primaryAlpha['20'],
          },
          header: {
            fontWeight: 'bold',
            bg: 'elevation.24dp',
            bgGradient: 'none',
            border: 'none',
          },
        },
        primary: {
          content: {
            bg: 'bg.primary',
            bgGradient: 'linear(to-r, primaryAlpha.20, primaryAlpha.20)',
            color: 'neutral.300',
            boxShadow: '12dp',
            '--popper-arrow-bg': colors.primaryAlpha['20'],
          },
          header: {
            fontWeight: 'bold',
            bg: 'bg.primary',
            bgGradient: 'linear(to-r, primaryAlpha.20, primaryAlpha.20)',
            border: 'none',
          },
        },
      },
    },
  },
});

export type Theme = typeof theme;
