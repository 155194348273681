import { makeApi } from '@zodios/core';
import { z } from 'zod';

import { looseEnum, zIsoDateTime } from '../lib/schema-utils';
import { streakSchema } from './user-profile';

export const ACILocationSchema = z.enum([
  'ramping_up',
  'in_the_thick',
  'winding_down',
  'recharging',
]);

export const ACISchema = z.object({
  offset: z.number(), // TODO - we scan remove this now that we have a Timezone request header
  // TODO fix the ramping_up in the score results
  arena_location: ACILocationSchema,
  current_stress: z.number().optional(),
  current_energy: z.number().optional(),
  current_focus: z.number().optional(),
  challenge_anticipated: z.number().optional(),
  preparedness: z.number().optional(),
  make_key_decisions: z.number().optional(),
  clinical_performance: z.number().optional(),
  recharging_needed: z.number().optional(),
  recharging_ability: z.number().optional(),
});

export const ACIScoreSchema = z.object({
  date: zIsoDateTime,
  score: z.union([
    z.literal(1),
    z.literal(2),
    z.literal(3),
    z.literal(4),
    z.literal(5),
  ]),
  label: z.string(),
  location: z.string(),
  trend: looseEnum(['positive', 'negative', 'neutral']).nullable(),
  image: z.string(),
  heading: z.string(),
  copy: z.string(),
});
export const ACIResponseSchema = z.object({
  created_at: zIsoDateTime,
  previous: ACIScoreSchema.nullable().catch(null),
  current: ACIScoreSchema,
  streak: streakSchema,
});

export const aciApi = makeApi([
  {
    alias: 'submitACI',
    description: 'Submit the ACI form',
    method: 'post',
    path: '/data/aci/',
    parameters: [
      {
        type: 'Body',
        name: 'data',
        schema: ACISchema,
      },
    ],
    response: ACIResponseSchema,
  },
]);
