import { makeApi, ZodiosResponseByAlias } from '@zodios/core';
import { z } from 'zod';

import { zIsoDateTime } from '../lib/schema-utils';
import { pillarPracticeSchema } from './pillars.schema';
import { wearableSchema } from './user-profile';

export type BiometricsResponse = ZodiosResponseByAlias<
  typeof biometricsApi,
  'getBiometrics'
>;

const BiometricSchema = z.object({
  last_updated: zIsoDateTime,
  last_synced: zIsoDateTime.nullable(),
  value_status: z.enum(['ok', 'not ok']),
  action_status: z.enum(['locked', 'ready', 'completed']),
  hint: z.string(),
  value: z.number(),
  target: z.number(),
  baseline_value: z.number(),
  buffer: z.number(),
  direction: z.enum(['up', 'down']),
  practice: pillarPracticeSchema,
  lessons_before_practice: z.number(),
});

export const BiometricsResponseSchema = z.object({
  wearable: z.array(wearableSchema).nullable(),
  hours_of_sleep: BiometricSchema.nullable(),
  rhr: BiometricSchema.nullable(),
  hrv: BiometricSchema.nullable(),
});

export const biometricsApi = makeApi([
  {
    alias: 'getBiometrics',
    description: `Get the user's biometrics`,
    method: 'get',
    path: '/data/',
    response: BiometricsResponseSchema,
  },
]);
