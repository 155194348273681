import { keyframes } from '@chakra-ui/react';
import { merge } from 'lodash-es';

import { Icon, IconProps } from '../common/icon';
import { ReactComponent as CheckedHexagonIcon } from './checked-hexagon.svg';

const fadeIn = keyframes`
  from { opacity: 0; }
  to { opacity: 1; }
`;

const draw = keyframes`
  to { stroke-dashoffset: 0; }
`;

export type AnimatedCheckedHexagonIconProps = Omit<IconProps, 'as'> & {
  /**
   * Toggle animation or static display
   */
  animate?: boolean;

  /**
   * Total animation duration in seconds
   */
  duration?: number;

  /**
   * Delay the start of the animation in seconds
   */
  delay?: number;
};

export function AnimatedCheckedHexagonIcon({
  animate,
  duration = 1.5,
  delay = 0,
  ...props
}: AnimatedCheckedHexagonIconProps) {
  const checkDuration = duration / 2.5;
  const segmentCount = 7; // There are 6 sides in the hexagon + 1 starting path segment
  const segmentDelay = duration / segmentCount;

  const animationStyles = {
    '[data-id="check"]': {
      // This is the total length of the path. use DOM method `getTotalLength()` to get it
      // e.g. `document.querySelector(('[data-id="check"]').getTotalLength())`
      // @see https://css-tricks.com/almanac/properties/s/stroke-dasharray/
      '--strokeLength': 41.89,
      strokeDashoffset: 'var(--strokeLength)',
      strokeDasharray: 'var(--strokeLength)',
      animation: `${draw} ${checkDuration}s ease-in forwards`,
      animationDelay: `${delay + duration - checkDuration}s`,
    },
    '[data-id="hexagon"] > *': {
      opacity: 0,
      animation: `${fadeIn} ease-in-out forwards`,
      animationDuration: `${segmentDelay}s`,
    },
    ...Object.fromEntries(
      Array.from({ length: segmentCount }, (_, i) => {
        return [
          `[data-id="hexagon"] > :nth-of-type(${i + 1})`,
          { animationDelay: `${delay + segmentDelay * i}s` },
        ];
      }),
    ),
  } as IconProps['sx'];

  return (
    <Icon
      {...props}
      as={CheckedHexagonIcon}
      sx={animate ? merge({}, props.sx, animationStyles) : props.sx}
    />
  );
}
