import { makeApi } from '@zodios/core';
import { z } from 'zod';

import { looseEnum, safeEnum, zIsoDateTime } from '../lib/schema-utils';

const biometricErrorsSchema = z.object({
  title: z.string(),
  message: z.string(),
  action: z.string().nullable(),
});

export type WearableProviders = z.infer<typeof wearableProviderSchema>;
export const wearableProviderSchema = z.enum(['whoop', 'oura', 'striveware']);

export const wearableSchema = z.object({
  provider: wearableProviderSchema,
  user_id: z.string().nullable(),
  password: z.string().nullable(),
  username: z.string().nullable(),
  email: z.string().nullable(),
  status: safeEnum(
    ['valid', 'invalid', 'revoked', 'expired'],
    'wearable.status',
  ),
  errors: biometricErrorsSchema.nullable(),
  url: z.string().nullable(),
  last_synced_at: zIsoDateTime.nullable(),
  is_synced_today: z.boolean().nullable(),
});
export type Wearable = z.infer<typeof wearableSchema>;

export const learningStateSchema = z.enum([
  'launchpad',
  'onboarding',
  'in_progress',
  'complete',
]);

export const streakSchema = z.object({
  current: z.number(),
  current_level: z.string(),
  max: z.number(),
  max_level: z.string(),
  days_since_learning_journey_started: z.number(),
  total_acis: z.number(),
});

export const userSchema = z.object({
  id: z.number(),
  username: z.string(),
  email: z.string().email().optional(),
  first_name: z.string(),
  last_name: z.string(),
  is_staff: z.boolean(),
  last_login: zIsoDateTime.nullable(),
});

const profileSchema = z.object({
  preferred_name: z.string().nullable(),
  selected_notification_time: z.string().nullable(),
  goal: z.number().nullable(),
  goal_text: z.string().nullable(),
  impersonated_by: z.number().nullable(),
  phone_number: z.string().nullable(),
  send_email: z.boolean().nullable(),
  send_push: z.boolean().nullable(),
  send_sms: z.boolean().nullable(),
  team: z.string().nullable(),
  team_id: z.number().nullable(),
  team_coach_stream_username: z.string().nullable(),
  channel_name_with_coach: z.string().nullable(),
  team_launchpad_stream_username: z.string().nullable().default(null),
  channel_name_with_launchpad: z.string().nullable().default(null),
  stream_token: z.string(),
  stream_username: z.string(),
  roles: z.array(looseEnum(['coach', 'leader', 'launchpad_guide'])),
  primary_care_environment: z.string().nullable(),
  specialty: z.string().nullable(),
  license_type: z.string().nullable(),
  wearable: z.array(wearableSchema),
});

export const userProfileApi = makeApi([
  {
    alias: 'getUserProfile',
    description: 'Fetch the user profile',
    method: 'get',
    path: '/profile/',
    response: z
      .object({
        user: userSchema,
        profile: profileSchema,
      })
      .transform((response) => {
        // Custom response to flatten the `user` so you can access user properties
        // directly, e.g. `user.id` instead of `user.user.id`
        return {
          ...response.user,
          profile: response.profile,
        };
      }),
  },
  {
    alias: 'updateUserData',
    description: 'Update the "user" record',
    method: 'put',
    path: '/profile/update_user/',
    parameters: [
      {
        type: 'Body',
        name: 'data',
        schema: userSchema
          .pick({ username: true, email: true })
          .merge(
            userSchema.pick({ first_name: true, last_name: true }).partial(),
          ),
      },
    ],
    response: userSchema,
  },
  {
    alias: 'updateUserProfile',
    description: `Update the user's "profile"`,
    method: 'patch',
    path: '/profile/update_profile/',
    parameters: [
      {
        type: 'Body',
        name: 'data',
        schema: profileSchema
          .pick({
            phone_number: true,
            preferred_name: true,
            send_email: true,
            send_sms: true,
            send_push: true,
          })
          .partial(),
      },
    ],
    response: profileSchema,
  },
  {
    alias: 'deactivateUser',
    description: 'Deactivate the user',
    method: 'put',
    path: '/profile/deactivate/',
    response: z.null(),
    parameters: [
      {
        type: 'Body',
        name: 'data',
        schema: z.void(),
      },
    ],
  },
  {
    alias: 'setLearningState',
    description: 'Set the learning state',
    method: 'put',
    path: '/profile/learning_state/',
    response: z.unknown(),
    parameters: [
      {
        type: 'Body',
        name: 'data',
        schema: z.object({
          learning_state: learningStateSchema,
        }),
      },
    ],
  },
]);
