import { useCallbackRef } from '@chakra-ui/hooks';
import { useMutation, useQueryClient } from '@tanstack/react-query';

import { UserUpdateBody } from '@arena-labs/shared-models';

import { useAuthState } from '../auth/auth-service';
import { $API, apiClient } from '../clients';

export function useUserProfile() {
  const [authState] = useAuthState();
  return $API.useGetUserProfile(
    {},
    {
      enabled:
        authState.matches('Logged in') ||
        authState.matches('Setting up profile'),
    },
  );
}

export function useInvalidateUserProfile() {
  const queryClient = useQueryClient();
  return useCallbackRef(() =>
    queryClient.invalidateQueries($API.getKeyByAlias('getUserProfile')),
  );
}

export function useUpdateUserProfileMutation() {
  const invalidateCachedProfile = useInvalidateUserProfile();
  return useMutation(
    (update: UserUpdateBody) =>
      Promise.all([
        update.profile && apiClient.updateUserProfile(update.profile),
        update.user && apiClient.updateUserData(update.user),
      ]),
    { onSettled: invalidateCachedProfile },
  );
}
